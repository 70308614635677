const Config = {
  Api:{
    Base:`https://ramankamisarau.com/backend`
  },
  Pagination: {
    ItemsPerPage: 5,
  },
  Calendar: {
    ConcertDuration: 2 * 60 * 60 * 1000,
  },
};

export default Config;