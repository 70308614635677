import ContactInfo from "../Components/Contact";

function Contact() {
  return (
    <div className="Contact">
      <h2>CONTACT ME</h2>
      <ContactInfo />
    </div>
  );
}

export default Contact;
