import * as React from "react";

function NotFound() {
  return (
    <div>
        Not Found
    </div>
  );
}

export default NotFound;
